@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --header-height: 70px;
}

html,
body,
#root,
.layout,
main {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.layout {
  display: flex;
  position: relative;
}

main {
  flex: 1;
  overflow: auto;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  min-height: calc(100vh - var(--header-height));
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
}

h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 1;
}

h3 {
  font-weight: 500;
  font-size: 20px;
  color: rgba(21, 21, 21, 0.8);
}

h4 {
  font-weight: 500;
  font-size: 16px;
  color: rgba(21, 21, 21, 0.75);
}

.error {
  color: red;
}

@media (max-width: 1700px) {
  h2 {
    font-size: 32px;
  }
}

@media (max-width: 567px) {
  .content {
    padding: 20px 16px;
  }
}

.link {
  font-weight: bold;
  color: #1976d2;
}
.panelLinks{
  display: flex;
  align-items: center;
  margin: 32px 0;
  gap: 24px;
  padding: 16px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 9px;
}

.panelLinks li.active{
  font-weight: bold;
}