.splash_screen {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash_screen .logo_wrapper {
  max-width: 230px;
}

@media (max-width: 767px) {
  .splash_screen .logo_wrapper {
    max-width: 180px;
  }
}
